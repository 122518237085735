<template>
  <div class="container-fluid multiple-tabs mt-3">

    <Form @submit="saveArticle" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'المقالات' , path: '/articles'}" current_page="إضافة مقال"/>
        <div class="controls-btns d-flex gap-2 mb-4">
          <button class="btn btn-publish" type="submit" @click="saveArticle">نشر</button>
          <button class="btn btn-save-draft">حفظ مسودة</button>
          <!--        <button class="btn btn-preview">معاينة</button>-->
        </div>
      </div>

      <el-card>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="المقال" name="article">
            <ArticleDetails :details="data" @update="handleOnChange" :contentError="contentError" :errors="errors"/>
          </el-tab-pane>
          <el-tab-pane label="محركات البحث" name="seo">
            <SEO :seo-details="data" @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="منصات التواصل الاجتماعي" name="social-media">
            <SocialMedia :social-data="data" @update="handleOnChange"/>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </Form>

  </div>
</template>

<script>
import {Form} from "vee-validate";

import Breadcrumb from "@/components/Breadcrumb";
import ArticleDetails from "@/views/articles/components/articleDetails";
import SEO from "@/views/articles/components/SEO";
import SocialMedia from "@/views/articles/components/socialMedia";
import {saveArticle, saveAsDraft} from "@/services/articles";
import {useToast} from "vue-toastification";

export default {
  name      : "Add_Article",
  components: {
    SocialMedia,
    SEO,
    ArticleDetails,
    Breadcrumb,
    Form
  },
  data()
  {
    return {
      toast: useToast(),
      activeTab   : 'article',
      data        : {
        id                : '',
        url_title         : '',
        title             : '',
        source            : '',
        content           : '',
        summary           : '',
        extract           : '',
        seo_name          : '',
        seo_title         : '',
        seo_description   : '',
        social_title      : '',
        twitter           : false,
        is_main_article   : false,
        social_description: '',
        social_image      : '',
        author            : '',
        image             : '',
        image_description : '',
        date_to_publish   : new Date(),
        time              : new Date().toLocaleTimeString('en-US', {hour: 'numeric', hour12: false, minute: 'numeric'}),
        tags              : [],
        twitter_edited    : 1,
        image_edited      : false,
        simage_edited     : false,
      },
      contentError: false
    }
  },
  mounted()
  {
    this.handleDraftObserver();
  },
  unmounted()
  {
    clearInterval(this.interval)
  },
  methods: {
    handleOnChange(key, val)
    {
      this.data = {
        ...this.data,
        [key]: val
      }
    },
    saveArticleDraft()
    {
      if (this.data.title.length > 10 && this.data.content.length > 10 && this.data.category_id)
      {
        saveAsDraft(this.data)
            .then(res => {
              this.toast.success('تم الحفظ بنجاح')
              this.data.id = res.data.id
            })
      }
    },

    handleDraftObserver()
    {
      this.interval = setInterval(this.saveArticleDraft, 10000)
    },

    saveArticle()
    {
      if (this.data.content.length === 0)
      {
        this.contentError = true
      } else
      {
        saveArticle(this.data)
            .then(res => {
             this.toast.success('تم الحفظ بنجاح')
              this.$router.push('/articles')
            })
            .catch(e => {
              console.log(e)
            })
      }

    }
  }
}
</script>

<style lang="scss">
.btn-save {
  background-color: #7367F0;
}

.btn-publish {
  background-color: #09c05b;
}

.btn-save-draft {
  background-color: #7367F0;
}

.btn-preview {
  background-color: #0dbfd4;
}

.controls-btns .btn {
  color: #fff;
  min-width: 100px;


  &:hover {
    opacity: 0.9;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .16);
  }
}


.el-tabs__nav {
  float: right;
}

</style>
